/**
 * Service Inspection
 * Service Inspection API
 *
 * The version of the OpenAPI document: v1
 * Contact: a.gell@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum CustomerInvitationStatus {
    AlreadyDone = 'AlreadyDone',
    NotAllowed = 'NotAllowed',
    DoneWithThisProcess = 'DoneWithThisProcess',
    NotYet = 'NotYet',
    ProductNotRegistered = 'ProductNotRegistered'
};

