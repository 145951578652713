/**
 * Service Inspection
 * Service Inspection API
 *
 * The version of the OpenAPI document: v1
 * Contact: a.gell@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum CheckpointStatusType {
    Ok = 'Ok',
    Deficiency = 'Deficiency',
    DeficiencySolved = 'DeficiencySolved',
    Changed = 'Changed',
    NotChanged = 'NotChanged',
    Checked = 'Checked',
    NotChecked = 'NotChecked',
    NotApplicable = 'NotApplicable'
};

