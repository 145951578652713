/**
 * Service Inspection
 * Service Inspection API
 *
 * The version of the OpenAPI document: v1
 * Contact: a.gell@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum CheckpointTypes {
    SP1 = 'SP1',
    SP3 = 'SP3',
    SP4 = 'SP4',
    SP5 = 'SP5'
};

